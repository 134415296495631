<template>
  <div class="title-container logo-border">
    <h1 class="title"><span class="part-1">Dombó</span><span class="part-2">címerezés</span></h1>
  </div>
</template>

<script>
export default {
  name: 'DomboTitle'

}
</script>

<style lang="scss" scoped>
  .title-container{
    display: block;
    padding: 5px 10px;

    .title{
      cursor: default;
      font-size: 1.5rem !important;
      color: grey;
      text-transform: uppercase;
      .part-1{
        font-weight: 300;
      }
      .part-2{
        font-weight: 500;
      }
    }
  }
</style>