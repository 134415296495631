<template>
  <div class="activity-controller-row bordered-1">
    <div class="activity-container">Tevékenység: {{ formatActivity() }}</div>
    <v-divider vertical></v-divider>
    <div class="controller-container">
      <div class="controller-text">Ellenőrök:</div>
      <div class="controller-chips">
        <div>
          <v-row>
            <v-col>
              <v-chip-group show-arrows class="chip-container">
                <v-chip
                  v-for="person in formatControllers()"
                  :key="person.id"
                  label
                  close
                  close-icon="mdi-close"
                  @click:close="removeController(person)"
                  >{{ person.firstname }} {{ person.lastname }}</v-chip
                >
              </v-chip-group>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div class="edit-container">
      <v-divider vertical></v-divider>
      <div @click="showDialog = true">
        <v-icon class="edit-icon">mdi-pencil-outline</v-icon>
      </div>
    </div>
    <ConfirmDelete
      :dialog="modals.confirmDelete"
      :requestMethod="deleteController"
      @show-dialog="(value) => (modals.confirmDelete = value)"
    />
  </div>
</template>

<script>
import ConfirmDelete from "@/components/modals/ConfirmDelete";
import { UPDATE_GROUP } from "@/apiRoutes";
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  data() {
    return {
      modals: {
        confirmDelete: false,
      },
      person: "",
      showDialog: false,
      controllers: [],
      selectedControllers: [],
      apiError: {
        show: false,
        message: "",
      },
    };
  },
  mounted() {},
  components: {
    ConfirmDelete,
  },
  methods: {
    removeController(person) {
      this.person = person;
      this.modals.confirmDelete = true;
    },
    composeControllers() {
      let employees = [];

      for (let person in this.selectedControllers) {
        employees.push({
          employee_id: this.selectedControllers[person].id,
        });
      }

      return {
        type: "Inspector",
        employees: employees,
      };
    },
    deleteController() {
      if (this.selectedControllers.includes(this.person)) {
        let index = this.selectedControllers.indexOf(this.person);
        this.selectedControllers.splice(index, 1);
      }

      const data = this.composeControllers();
      let group_id = null;

      for (let i in this.event.groups) {
        if (this.event.groups[i].type == "Inspector") {
          group_id = this.event.groups[i].id;
        }
      }

      const queryParams = {
        group_id: group_id,
      };

      this.updateInspector(data, queryParams);
    },
    updateInspector(data, queryParams) {
      this.ApiService(UPDATE_GROUP, data, queryParams)
        .put()
        .then((res) => {
          if (typeof res.data != "undefined" && res.data !== null) {
            store.commit("updateControllers", res.data);
            this.modals.confirmDelete = false;
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "Az ellenőr törlése sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    formatControllers() {
      const controllersGroup = this.employees.filter((person) => {
        for (let i in this.controllers) {
          if (person.id == this.controllers[i]) {
            return person;
          }
        }
      });

      return (this.selectedControllers = controllersGroup);
    },
    formatActivity() {
      const activity = this.activities.filter((activity) => {
        if (activity.id == this.event.activity_id || activity.id == activity) {
          return activity;
        }
      });

      return activity[0].name;
    },
  },
  watch: {
    showDialog: function () {
      this.$emit("show-dialog", this.showDialog);
    },
    dialog: function () {
      this.$emit("selected-controllers", this.selectedControllers);
      this.showDialog = this.dialog;
    },
    groups: {
      immediate: true,
      handler(value) {
        this.controllers = [];
        for (let i in value) {
          if (value[i].type == "Inspector") {
            for (let person in value[i].employees) {
              this.controllers.push(value[i].employees[person].employee_id);
            }
          }
        }
        
        //this.composeControllers();
      },
    },
  },
  props: {
    dialog: Boolean,
    activity: Number,
  },
  computed: {
    ...mapGetters({
      event: "returnEvent",
      activities: "returnActivities",
      employees: "returnEmployees",
    }),
    groups() {
      return this.event.groups;
    },
  },
};
</script>

<style>
</style>