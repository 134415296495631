<template>
  <v-dialog class="modal" v-model="showDialog" width="500" persistent>
    <v-card class="modal-card">
      <v-card-title class="card-header">
        Új erőforrás hozzáadása
        <div class="close-container" @click="showDialog = false">
          <v-icon class="close-icon">mdi-close</v-icon>
        </div>
      </v-card-title>

      <v-card-text class="card-body">
        <v-tabs
          class="tabs"
          centered
          grow
          color="black"
          v-model="tab"
          @change="clearInputValues()"
        >
          <v-tab class="tab d-flex" :disabled="isDisabled">
            <v-icon>mdi-application-import</v-icon>
            <span>Importálás</span>
          </v-tab>
          <v-tab class="tab">
            <v-icon>mdi-magnify</v-icon>
            <span>Hozzáadás</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-row align="center" class="input-row mt-5">
              <TableSelect
                :table="this.input.table"
                @corntable-input="(value) => (this.input.table = value)"
              />
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row align="center" class="input-row mt-5">
              <v-col class="input-col newresource d-flex" cols="12">
                <v-autocomplete
                  v-model="selectedEmployee"
                  :items="employees"
                  :item-value="(item) => item"
                  :item-text="(item) => item.firstname + ' ' + item.lastname"
                  label="Emberek hozzáadása"
                  prepend-inner-icon="mdi-magnify"
                  :item-disabled="this.disablePickedUsers"
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row align="center" class="input-row">
              <v-col>
                <v-chip-group show-arrows class="chip-container">
                  <v-chip
                    v-for="person in input.workers"
                    :key="person.id"
                    label
                    close
                    close-icon="mdi-close"
                    @click:close="removeWorker(person)"
                    >{{ person.firstname }} {{ person.lastname }}</v-chip
                  >
                </v-chip-group>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions class="card-footer">
        <v-divider></v-divider>
        <v-spacer></v-spacer>
        <div class="buttons-container">
          <v-btn
            color="primary"
            elevation="2"
            block
            x-large
            class="save-btn"
            @click="save()"
          >
            <span class="save-btn-text">Mentés</span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TableSelect from "@/components/TableSelect";
import { ADD_GROUP, IMPORT_GROUP, SEARCH_EVENT } from "@/apiRoutes";
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  data() {
    return {
      tab: null,
      isDisabled: false,
      showDialog: false,
      selectedEmployee: null,
      input: {
        table: null,
        workers: [],
      },
      apiError: {
        show: false,
        message: "",
      },
    };
  },
  components: {
    TableSelect,
  },
  computed: {
    ...mapGetters({
      event: "returnEvent",
      employees: "returnEmployees",
    }),
    groups() {
      return this.event.groups;
    },
    selectedTable() {
      return this.input.table;
    },
  },
  methods: {
    clearInputValues() {
      this.input.table = null;
      this.input.team = null;
    },
    removeWorker(person) {
      if (this.input.workers.includes(person)) {
        let index = this.input.workers.indexOf(person);
        this.input.workers.splice(index, 1);
      }
    },
    addWorker(data, queryParams) {
      this.ApiService(ADD_GROUP, data, queryParams)
        .post()
        .then((res) => {
          
          if (typeof res.data != "undefined" && res.data !== null) {
            // console.log(res.data);
            store.commit("setWorkers", res.data);
            this.showDialog = false;
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "Az ellenőrök törlése sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    composeWorkers() {
      let employees = [];

      for (let person in this.input.workers) {
        employees.push({
          employee_id: this.input.workers[person].id,
        });
      }

      return {
        type: "Worker",
        employees: employees,
      };
    },
    addGroup(data) {
      this.ApiService(IMPORT_GROUP, data)
        .post()
        .then((res) => {
          let data = JSON.parse(JSON.stringify(res.data));
          console.log("ADDGROUP", data);
          if (typeof res.data != "undefined" && res.data !== null) {
            store.commit("importWorkers", data);
            this.showDialog = false;
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "Az ellenőrök törlése sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    searchEvent(queryParams) {
      this.ApiService(SEARCH_EVENT, {}, queryParams)
        .get()
        .then((res) => {
          if (typeof res.data != "undefined" && res.data !== null) {
            if (res.data.groups.length > 0) {
              var employees = [];
              //var result = {};
              for (let i in res.data.groups) {
                if (res.data.groups[i].type == "Worker") {
                  for (let person in res.data.groups[i].employees) {
                    employees.push({
                      employee_id:
                        res.data.groups[i].employees[person].employee_id,
                    });
                  }

                }
               /*  result = {
                  type: "Worker",
                  employees: employees,
                }; */
              }
              //const data = result;

              /* const queryParams = {
                event_id: this.event.id,
              }; */
              let data = JSON.parse(JSON.stringify(res.data));
              data.event_id = this.event.id;
              console.log("result search",data);
              this.addGroup(data);
            } else {
              this.apiError.show = true;
              this.apiError.message = "A táblához nem tartozik csapat";

              store.commit("setErrors", this.apiError);

              let vm = this;
              setTimeout(() => {
                vm.apiError.show = false;
                vm.apiError.message = "";

                store.commit("setErrors", this.apiError);
              }, 5000);
            }
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "A tábla importálása sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    importWorkers(queryParams) {
      this.searchEvent(queryParams);
    },
    disablePickedUsers(item) {
      let asd = null;
      this.groups.forEach(group => {
        if(group.employees) {

          group.employees.forEach(groupEmployee => {
            if(groupEmployee.employee_id === item.id) {
              if(!groupEmployee.drop_time) {
                asd = item;
            }
          }
        })
        }
      });
      return asd;
    },
    save() {
      if (this.tab == 0) {
        if (this.input.table !== null) {
          const queryParams = {
            corntable_id: this.input.table,
            date: this.event.date,
          };

          this.importWorkers(queryParams);
        }
      } else if (this.tab == 1) {
        if (this.input.workers?.length > 0) {
          const data = this.composeWorkers();

          const queryParams = {
            event_id: this.event.id,
          };

          this.addWorker(data, queryParams);
        }
      }
    },
  },
  watch: {
    dialog: function () {
      this.input.workers = [];
      this.selectedEmployee = null;
      this.showDialog = this.dialog;
    },
    showDialog: function () {
      this.$emit("show-dialog", this.showDialog);
    },
    groups: {
      immediate: true,
      handler(value) {
        for (let i in value) {
          if (value[i].type == "Worker") {
            this.isDisabled = false;
          } else {
            this.isDisabled = true;
          }
        }
      },
    },
    selectedEmployee: function (value) {
      if (value !== null) {
        var found = false;

        for (var i = 0; i < this.input.workers.length; i++) {
          if (this.input.workers[i].id == value.id) {
            found = true;
            break;
          }
        }

        if (!found) {
          this.input.workers.push(value);
        }
      }
    },
  },
  props: {
    dialog: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.table-name,
.team,
.newresource {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.modal-card {
  height: 500px;
}
.v-tab {
  justify-content: space-around;
  padding: 10px;
  font-size: 1.15rem;
  text-transform: unset !important;
}
.card-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>