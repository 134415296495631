<template>
  <div class="wrapper">
    <v-card class="team bordered-1" elevation="0">
      <v-card-title class="card-header">
        <h2 class="title">{{ index + 1 }}. Csapat</h2>
        <div v-if="showTrash" class="edit-icon-wrapper" @click="reqDeleteTeam()">
          <v-icon class="edit-icon">mdi-trash-can-outline</v-icon>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="card-body">
        <div class="name-container">
          <div
            :class="['name-text', person.drop_bords_table_id != null ? 'line-through' : '']"
            v-for="person in this.employeees"
            :key="person.id"
          >
            {{ person.employee.firstname }} {{ person.employee.lastname }}
            <v-icon class="close-icon" @click="removeWorker(person)"
              >mdi-close</v-icon
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
    <!-- <EditTeamModal
      :dialog="modals.editTeam.showDialog"
      :selectedWorkers="workers"
      :data="modals.editTeam.data"
      @show-dialog="(value) => (modals.editTeam.showDialog = value)"
    /> -->
    <ConfirmDelete
      :dialog="modals.confirmDelete"
      :requestMethod="deleteWorker"
      @show-dialog="(value) => (modals.confirmDelete = value)"
    />

    <ConfirmDeleteTeam
      :dialog="modals.confirmDeleteTeam"
      :requestMethod="deleteTeam"
      @show-dialog="(value) => (this.modals.confirmDeleteTeam = value)"
    />
  </div>
</template>

<script>
import ConfirmDelete from "@/components/modals/ConfirmDelete";
import ConfirmDeleteTeam from "@/components/modals/ConfirmDeleteTeam";
// import EditTeamModal from "@/components/modals/EditTeam";
import { UPDATE_GROUP, UPDATE_GROUP_EMPLOYEE, DELETE_GROUP } from "@/apiRoutes";
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  data() {
    return {
      showTrash: true,
      showDialog: false,
      person: "",
      workers: [],
      modals: {
        editTeam: {
          showDialog: false,
          data: {
            groupId: null,
          },
        },
        confirmDelete: false,
        confirmDeleteTeam: false,
      },
      apiError: {
        show: false,
        message: "",
      },
    };
  },
  mounted() {
    /*  this.workers = [];
    for (let i in this.groups) {
      if (this.groups[i].type == "Worker" && this.groups[i].id == this.groupId) {
        for (let person in this.groups[i].employees) {
          if (!this.workers.includes(this.groups[i].employees[person].employee_id)) {
            this.workers.push(this.groups[i].employees[person].employee_id);
          }
          if(this.groups[i].bords.length > 0) {
            this.showTrash = false
          }
        }
      }
    }  */
    if(this.bords.length > 0) this.showTrash = false;
  },
  computed: {
    ...mapGetters({
      event: "returnEvent",
      errors: "returnErrors",
      employees: "returnEmployees",
    }),
    groups() {
      return this.event.groups;
    },
  },
  components: {
    // EditTeamModal,
    ConfirmDelete,
    ConfirmDeleteTeam,
  },
  methods: {
    openEditModal() {
      this.modals.editTeam.showDialog = true;
      this.modals.editTeam.data.groupId = this.groupId;
    },
    reqDeleteTeam(){
        this.modals.confirmDeleteTeam = true;
    },

    deleteTeam() {
      const queryParams = {
        group_id: this.groupId,
      };

      this.ApiService(DELETE_GROUP, {}, queryParams)
        .delete()
        .then((res) => {
          if (typeof res.data != "undefined" && res.data !== null) {
            this.modals.confirmDelete = false;
            this.showDialog = false;

            store.commit("deleteGroup", this.groupId);
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "A frissítés sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    removeWorker(person) {
      this.person = person;
      this.modals.confirmDelete = true;
      // console.log(this.person);
    },
    isPersonLastMembrOfGroup() {
      let counter = 0;
      this.employeees.forEach(emp => {
          if(emp.drop_time === null) {
            counter++;
          }
      })
      console.log("counter",counter);
      if(counter === 1) return true;
      return false;
    },
    deleteWorker() {
      let bord = null;
      let deletable = false;
      console.log("EMPLOYEES", this.employeees);
      if (!this.isPersonLastMembrOfGroup()) {
        /* if (this.workers.includes(this.person.id)) {
          let index = this.workers.indexOf(this.person.id);
          this.workers.splice(index, 1);
        } */
        if(this.bords.length > 0) {
          // console.log("teamcarddelete",this.bords);
          //Mindig az első bordról esik ki a személy. Ezt a pászta számot mentjük dbbe.+ Date
          bord = this.bords[0];

          const data = {
            drop_bords_table_id: bord.id
          }
          const queryParams = {
            id: this.person.id,
          };

          this.deleteGroupEmployee(data, queryParams);
          deletable = true;
        }  

        if(!deletable) {
          this.apiError.show = true;
          this.apiError.message = "Pászta nélkül nem törölhető";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        }
        
      } else {
        this.apiError.show = true;
        this.apiError.message = "Az utolsó ember nem törölhető";

        store.commit("setErrors", this.apiError);

        let vm = this;
        setTimeout(() => {
          vm.apiError.show = false;
          vm.apiError.message = "";

          store.commit("setErrors", this.apiError);
        }, 5000);
      }
    },
    deleteGroupEmployee(data, queryParams) {
      this.ApiService(UPDATE_GROUP_EMPLOYEE, data, queryParams)
        .patch()
        .then((res) => {
          if (typeof res.data != "undefined" && res.data !== null) {
            store.commit("updateWorkers", res.data);
            this.modals.confirmDelete = false;
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "Az ellenőr törlése sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    updateWorker(data, queryParams) {
      this.ApiService(UPDATE_GROUP, data, queryParams)
        .put()
        .then((res) => {
          if (typeof res.data != "undefined" && res.data !== null) {
            store.commit("updateWorkers", res.data);
            this.modals.confirmDelete = false;
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "Az ellenőr törlése sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    formatWorkers() {
      const workersGroup = this.employees.filter((person) => {
        for (let i in this.workers) {
          if (person.id == this.workers[i]) {
            return person;
          }
        }
      });

      return workersGroup;
    },
    getGroupEmployeeById() {
    },
    composeWorkers() {
      let employees = [];

      for (let person in this.workers) {
        employees.push({
          employee_id: this.workers[person],
        });
      }

      return {
        type: "Worker",
        employees: employees,
      };
    },
  },
  watch: {
    groups: {
      deep: true,
      handler(value) {
        this.workers = [];
        // console.log("változik a groups in team card");
        // console.log(value.bords);
        for (let i in value) {
          if (value[i].type == "Worker" && value[i].id == this.groupId) {
            for (let person in value[i].employees) {
              if (
                !this.workers.includes(value[i].employees[person].employee_id)
              ) {
                this.workers.push(value[i].employees[person].employee_id);
              }
            }
          }
        }
      },
    },
    bords(newVal) {
      console.log("bordsWatcher", newVal); 
      if(newVal.length > 0) this.showTrash = false;
    }
  },
  props: {
    dialog: Boolean,
    index: Number,
    groupId: Number,
    employeees: Array,
    bords: Array,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: contents;
}
.name-text {
  display: flex;
  justify-content: space-between;
}
.line-through {
  text-decoration: line-through;
}
</style>