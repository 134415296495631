<template>
  <v-dialog
    class="modal"
    v-model="showDialog"
    width="500"
    persistent
    scrollable
  >
    <v-card class="modal-card">
      <v-card-title class="card-header">
        Pászta módosítása
        <div class="close-container" @click="closeModalEvent">
          <v-icon class="close-icon">mdi-close</v-icon>
        </div>
      </v-card-title>

      <!-- <v-card-text class="card-body">
        <div class="number-container">
          <v-sheet
            v-for="(parcelNumber, index) in event.corn_table.bordrows"
            :key="index"
            class="number-wrapper bordered-2 color-grey"
            :class="
              selectedParcel.bordrow.id == parcelNumber.id
                ? 'color-white bg-black border-black'
                : 'color-grey'
            "
            elevation="0"
            rounded
          >
            <div class="number">{{ parcelNumber.name }}</div>
          </v-sheet>
        </div>
      </v-card-text> -->

      <v-card-text class="card-body">
        <div class="number-container">
          <v-sheet
            v-for="(parcelNumber, index) in event.corn_table.bordrows"
            :key="index"
            class="number-wrapper bordered-2 color-grey"
            :class="[
              selectedParcel.bordrow.id == parcelNumber.id
                ? 'color-white bg-black border-black'
                : 'color-grey'
            ,isFinished(parcelNumber) && selectedParcel.bordrow.id != parcelNumber.id ? 'parcel-bg-gray' : ''
            
            ]"
            elevation="0"
            rounded
          >
            <div :class="[isFinished(parcelNumber) ? 'white--text' : '' ,'number']">{{   isTort(parcelNumber) ? parcelNumber.name + '/' +  parcelNumber.remainingNumber : parcelNumber.name    }}</div>
          </v-sheet>
        </div>
      </v-card-text>

      <v-card-actions class="card-footer">
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <div class="additional">
          <v-col class="input-col rowscount d-flex" cols="5">
            <label for="rowscount">Sorok száma</label>
            <v-text-field
              id="rowscount"
              name="rowscount"
              type="number"
              outlined
              prepend-inner-icon="mdi-minus"
              append-icon="mdi-plus"
              v-model="input.rowsCount"
              :error-messages="validateRowsCount"
              @change="$v.input.rowsCount.$touch()"
              @blur="$v.input.rowsCount.$touch()"
              @click:append="increment()"
              @click:prepend-inner="decrement()"
            ></v-text-field>
          </v-col>
          <v-col class="input-col penalty d-flex" cols="5">
            <label for="penalty">Büntetés</label>
            <!-- <v-text-field
              id="penalty"
              name="penalty"
              v-model="input.penalty"
              :error-messages="validatePenalty"
              @change="$v.input.penalty.$touch()"
              @blur="$v.input.penalty.$touch()"
              type="number"
              outlined
            ></v-text-field> -->
            <v-checkbox
              id="penalty"
              name="penalty"
              v-model="input.penalty"
              outlined
            >

            </v-checkbox>
          </v-col>
        </div>
        <div class="buttons-container">
          <v-btn
            color="primary"
            elevation="2"
            block
            x-large
            class="save-btn"
            :disabled="$v.$invalid"
            @click="save()"
          >
            <span class="save-btn-text">Mentés</span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { between } from "vuelidate/lib/validators";
import { UPDATE_BORD } from "@/apiRoutes";
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  mixins: [validationMixin],
  validations: {
    input: {
      rowsCount: {
        between: between(1, 6),
      },
      penalty: {
        between: between(0, 100),
      },
    },
  },
  mounted() {
  },
  data() {
    return {
      input: {
        rowsCount: 1,
        penalty: 0,
        selectedParcel: null,
        selectedParcelIndex: null,
      },
      showDialog: false,
      apiError: {
        show: false,
        message: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      event: "returnEvent",
      errors: "returnErrors",
    }),
    calculateRemaningRows() {
       let calculatedRows = this.event.corn_table.bordrows.map(bordrow => {
         let actualNumber = bordrow.max_row_number;
         //console.log("actualNumber",actualNumber);
        this.event.groups.forEach(group => {
          group.bords.forEach(bord => {
            if(bord.bord_row_id === bordrow.id) {
              actualNumber = actualNumber - bord.row_number;
            }
          })
        }) 
        bordrow.remainingNumber = actualNumber;
        return bordrow;
      });
      //console.log("calculatedRow",calculatedRows);
      return calculatedRows;
    },
    validatePenalty() {
      const errors = [];
      if (!this.$v.input.penalty.$dirty) return errors;
      !this.$v.input.penalty.between &&
        errors.push("0 - 100 közötti értéket adj meg");
      return errors;
    },
    validateRowsCount() {
      const errors = [];
      if (!this.$v.input.rowsCount.$dirty) return errors;
      !this.$v.input.rowsCount.between &&
        errors.push("1 - 3 közötti értéket adj meg");
      return errors;
    },
  },
  methods: {
    isFinished(parcelNumber) {
      if(parcelNumber.remainingNumber == 0) {
        return true;
      }
      return false;
    },
    isTort(parcel) {
      if(parcel.max_row_number == parcel.remainingNumber || parcel.remainingNumber == 0) {
        return false;
      }
      return true;
    },
    closeModalEvent() {
      this.$emit('closeModifyModal');
    },
    selectParcel(parcelNumber) {
      this.input.selectedParcel = parcelNumber;
      //this.input.selectedParcelIndex = index;
    },
    increment() {
      if(this.input.rowsCount === (this.input.selectedParcel.bordrow.remainingNumber + this.input.selectedParcel.row_number)) return;
      this.input.rowsCount++;
    },
    decrement() {
      if (this.input.rowsCount < 2) return;
      this.input.rowsCount--;
    },
    updateParcel(data, queryParams) {
      this.ApiService(UPDATE_BORD, data, queryParams)
        .put()
        .then((res) => {
          if (typeof res.data != "undefined" && res.data !== null) {
            store.commit("updateBord", res.data);
            this.showDialog = false;
          }

        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "A pászta mentése sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    save() {
      if (this.$v.$invalid == true) {
        return;
      } else {
        const data = {
          group_id: this.groupId,
          bord_row_id: this.input.selectedParcel,
          row_number: this.input.rowsCount,
          penalty: this.input.penalty,
        };

        let queryParams = {
          bord_id: this.input.selectedParcel.id
        }
        // console.log(this.input.selectedParcel.id);
        this.updateParcel(data,queryParams);
      }
    },
  },
  watch: {
    dialog: function () {
      this.showDialog = this.dialog;
    },
    showDialog: function () {
      this.$emit("show-dialog", this.showDialog);
    },
    selectedParcel: function(newParcel) {
      this.input.selectedParcel = newParcel;
      console.log("SELECTED,",newParcel)
      this.input.penalty = newParcel.penalty;
      this.input.rowsCount = newParcel.row_number;
    }
  },
  props: {
    dialog: Boolean,
    groupId: Number,
    selectedParcel: Object,
  },
};
</script>

<style lang="scss">
.modal-card {
  overflow: hidden;
  .card-body {
    overflow: auto;
    .number-container {
      display: flex;
      justify-items: flex-start;
      flex-wrap: wrap;
      padding: 0px 8px;
      .number-wrapper {
        font-weight: 700;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        height: 50px;
        width: 50px;
      }
    }
  }
  .card-footer {
    .v-divider {
      margin-bottom: 20px;
    }
    .additional {
      display: flex;
      justify-content: center;
    }
    .rowscount,
    .penalty {
      flex-direction: column;
    }
    .rowscount {
      .error--text {
        .v-text-field__slot {
          transition: border-color 0.4s ease;
          border-right: 2px solid #ff5252 !important;
          border-left: 2px solid #ff5252 !important;
        }
      }
      .v-input--is-focused {
        .v-text-field__slot {
          transition: border-color 0.4s ease;
          border-right: 2px solid black;
          border-left: 2px solid black;
        }
      }
      .v-text-field__slot {
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid rgba(0, 0, 0, 0.38);
        border-left: 1px solid rgba(0, 0, 0, 0.38);
      }
    }
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>