// export const BASE_API_URL = "http://127.0.0.1:8000/api"
export const BASE_API_URL = "https://admin.dombocimerezes.cubicfoxdev.com/api"



export const CORNTABLE_ALL = '/corntable/all'
export const ACTIVITY_ALL = '/activity/all'
export const EMPLOYEE_ALL = '/employee/all'


export const CREATE_EVENT = '/event/store'
export const SEARCH_EVENT = '/event/:corntable_id/:date/search'
export const UPDATE_EVENT = '/event/:event_id/update'


export const ADD_GROUP = '/event/:event_id/group/store'
export const UPDATE_GROUP = '/event/group/:group_id/update'
export const DELETE_GROUP = '/event/group/:group_id/delete'
export const IMPORT_GROUP = '/event/existing/store'

export const UPDATE_GROUP_EMPLOYEE = '/event/group/employee/:id/update'

export const STORE_BORD = '/bord/store'
export const DELETE_BORD = '/bord/:bord_id/delete'
export const UPDATE_BORD = '/bord/:bord_id/update'

export const DOWNLOAD_PDF = '/export/:date'
export const END_EVENT = '/:id/end'
export const LOGIN = '/login'