import Vue from 'vue'
import Router from 'vue-router'
import Finder from '@/views/Finder'
import Dashboard from '@/views/Dashboard'
import Login from '@/views/Login'
import NotFound from '@/views/NotFound'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', component: Login, redirect: '/login' },
    { path: '/nap', component: Finder },
    { path: '/kezdolap', component: Dashboard },
    { path: '/login', component: Login },
    {
      path: '*',
      name: 'not-found',
      meta: { public: true },
      component: NotFound,
    },
  ]
})

export default router