<template>
  <v-dialog
    class="modal"
    v-model="showDialog"
    width="500"
    persistent
    scrollable
  >
    <v-card class="modal-card">
      <v-card-title class="card-header">
        Új pászta
        <div class="close-container" @click="showDialog = false">
          <v-icon class="close-icon">mdi-close</v-icon>
        </div>
      </v-card-title>
      <v-card-subtitle class="card-sub">
        Pásztaszám kiválasztása
      </v-card-subtitle>

      <v-card-text class="card-body">
        <div class="number-container">
          <v-sheet
            v-for="(parcelNumber, index) in calculateRemaningRows"
            :key="index"
            @click="selectParcel(parcelNumber, index)"
            class="number-wrapper bordered-2 color-grey"
            :class="[
              input.selectedParcel == parcelNumber.id
                ? 'color-white bg-black border-black'
                : 'color-grey'
            ,isFinished(parcelNumber) ? 'parcel-bg-gray' : ''
            
            ]"
            elevation="0"
            rounded
          >
            <div :class="[isFinished(parcelNumber) ? 'white--text' : '' ,'number']">{{   isTort(parcelNumber) ? parcelNumber.name + '/' +  parcelNumber.remainingNumber : parcelNumber.name    }}</div>
          </v-sheet>
        </div>
      </v-card-text>

      <v-card-actions class="card-footer">
        <v-spacer></v-spacer>
        <v-divider></v-divider>
        <div class="additional">
          <v-col class="input-col rowscount d-flex" cols="5">
            <label for="rowscount">Sorok száma</label>
            <v-text-field
              id="rowscount"
              name="rowscount"
              type="number"
              outlined
              prepend-inner-icon="mdi-minus"
              append-icon="mdi-plus"
              v-model="input.rowsCount"
              :disabled="input.rowsCount === 0 ? true : false"
              @click:append="increment()"
              @click:prepend-inner="decrement()"
            ></v-text-field>
          </v-col>
          <v-col class="input-col penalty d-flex" cols="5">
            <label for="penalty">Büntetés</label>
            <!-- <v-text-field
              id="penalty"
              name="penalty"
              v-model="input.penalty"
              :error-messages="validatePenalty"
              @change="$v.input.penalty.$touch()"
              @blur="$v.input.penalty.$touch()"
              type="number"
              outlined
            ></v-text-field> -->
            <v-checkbox
              id="penalty"
              name="penalty"
              v-model="input.penalty"
              outlined
            >

            </v-checkbox>
          </v-col>
        </div>
        <div class="buttons-container">
          <v-btn
            color="primary"
            elevation="2"
            block
            x-large
            class="save-btn"
            :disabled="$v.$invalid"
            @click="save()"
          >
            <span class="save-btn-text">Mentés</span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { between } from "vuelidate/lib/validators";
import { STORE_BORD } from "@/apiRoutes";
import { mapGetters } from "vuex";
import store from "@/store";
export default {
  mixins: [validationMixin],
  validations: {
    input: {
      penalty: {
        between: between(0, 100),
      },
    },
  },
  data() {
    return {
      input: {
        rowsCount: 0,
        penalty: 0,
        selectedParcel: null,
        selectedParcelIndex: null,
      },
      showDialog: false,
      apiError: {
        show: false,
        message: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      event: "returnEvent",
      errors: "returnErrors",
    }),
    calculateRemaningRows() {
       let calculatedRows = this.event.corn_table.bordrows.map(bordrow => {
         let actualNumber = bordrow.max_row_number;
         //console.log("actualNumber",actualNumber);
        this.event.groups.forEach(group => {
          group.bords.forEach(bord => {
            if(bord.bord_row_id === bordrow.id) {
              actualNumber = actualNumber - bord.row_number;
            }
          })
        }) 
        bordrow.remainingNumber = actualNumber;
        return bordrow;
      });
      //console.log("calculatedRow",calculatedRows);
      return calculatedRows;
    },
    validatePenalty() {
      const errors = [];
      if (!this.$v.input.penalty.$dirty) return errors;
      !this.$v.input.penalty.between &&
        errors.push("0 - 100 közötti értéket adj meg");
      return errors;
    },
  },
  methods: {
    isFinished(parcelNumber) {
      if(parcelNumber.remainingNumber == 0) {
        return true;
      }
      return false;
    },
    selectParcel(parcelNumber, index) {
      if(!this.isFinished(parcelNumber)) {
        this.input.selectedParcel = parcelNumber.id;
        this.input.selectedParcelIndex = index;
        this.input.rowsCount = parcelNumber.remainingNumber;
      }
    },
    isTort(parcel) {
      if(parcel.max_row_number == parcel.remainingNumber || parcel.remainingNumber == 0) {
        return false;
      }
      return true;
    },
    increment() {
      if(!this.input.selectedParcel) return;
      if(this.input.rowsCount === this.calculateRemaningRows[this.input.selectedParcelIndex].remainingNumber) return;
      this.input.rowsCount++;
    },
    decrement() {
      if (this.input.rowsCount < 2) return;
      this.input.rowsCount--;
    },
    addParcel(data) {
      this.ApiService(STORE_BORD, data)
        .post()
        .then((res) => {
          if (typeof res.data != "undefined" && res.data !== null) {
            store.commit("setBords", res.data);
            this.showDialog = false;
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "A pászta mentése sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    save() {
      if (this.$v.$invalid == true) {
        return;
      }  else {
        const data = {
          group_id: this.groupId,
          bord_row_id: this.input.selectedParcel,
          row_number: this.input.rowsCount,
          penalty: this.input.penalty,
        };
        this.input.selectedParcel = null;
        this.input.selectedParcelIndex = null;
        this.addParcel(data);
      }
    },
  },
  watch: {
    dialog: function () {
      this.showDialog = this.dialog;
    },
    showDialog: function () {
      this.$emit("show-dialog", this.showDialog);
    },
  },
  props: {
    dialog: Boolean,
    groupId: Number,
  },
};
</script>

<style lang="scss">
.parcel-bg-gray {
  background-color: #A1A1A1 !important;
}
.modal-card {
  overflow: hidden;
  .card-body {
    overflow: auto;
    .number-container {
      display: flex;
      justify-items: flex-start;
      flex-wrap: wrap;
      padding: 0px 8px;
      .number-wrapper {
        font-weight: 700;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        height: 50px;
        width: 50px;
      }
    }
  }
  .card-footer {
    .v-divider {
      margin-bottom: 20px;
    }
    .additional {
      display: flex;
      justify-content: center;
    }
    .rowscount,
    .penalty {
      flex-direction: column;
    }
    .rowscount {
      .error--text {
        .v-text-field__slot {
          transition: border-color 0.4s ease;
          border-right: 2px solid #ff5252 !important;
          border-left: 2px solid #ff5252 !important;
        }
      }
      .v-input--is-focused {
        .v-text-field__slot {
          transition: border-color 0.4s ease;
          border-right: 2px solid black;
          border-left: 2px solid black;
        }
      }
      .v-text-field__slot {
        padding-left: 10px;
        padding-right: 10px;
        border-right: 1px solid rgba(0, 0, 0, 0.38);
        border-left: 1px solid rgba(0, 0, 0, 0.38);
      }
    }
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>