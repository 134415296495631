import axios from 'axios/dist/axios.min.js'
import { BASE_API_URL } from '@/apiRoutes'

export default function ApiService(route, opts = {}, params = '') {
    if (typeof route !== 'string') {
        throw new TypeError('[ApiService]: "route" must be a string')
    }

    var defaultRoute = BASE_API_URL + route;
    const defaultOpts = Object.assign({}, {...opts}, {headers: { 'Authorization': `Bearer ${localStorage.token}` }})
    const defaultParams = Object.assign({}, {...params})
    const defaultHeaders = {headers: { 'Authorization': `Bearer ${localStorage.token}` }}

    var _buildQuery = function(defaultRoute, defaultParams) {
        if(typeof defaultParams === 'object' && defaultParams !== null) {
            let url = defaultRoute

            for(let prop in defaultParams){
                defaultParams[prop]?.toString()
                url = url.replace(':' + prop, defaultParams[prop])
            }
            return url
        } else {
            return ''
        }
    }

    if(params !== '') defaultRoute = BASE_API_URL + _buildQuery(route, defaultParams, defaultHeaders)

    var _get = function(){
        return axios.get(defaultRoute, defaultOpts, defaultHeaders)
    }

    var _post = function(){
        return axios.post(defaultRoute, defaultOpts, defaultHeaders)
    }

    var _put = function(){
        return axios.put(defaultRoute, defaultOpts, defaultHeaders)
    }

    var _patch = function() {
        return axios.patch(defaultRoute, defaultOpts, defaultHeaders)
    }

    var _delete = function(){
        return axios.delete(defaultRoute, defaultOpts, defaultHeaders)
    }

    var _download = function(){
        return axios.get(defaultRoute, {responseType: 'blob', headers: { 'Authorization': `Bearer ${localStorage.token}` }})
    }

    return {
        get: _get,
        post: _post,
        put: _put,
        delete: _delete,
        download: _download,
        patch: _patch,

    }
}
