<template>
  <div class="wrapper">
    <v-card class="parcel-card bordered-1" elevation="0">
      <v-card-title class="card-header">
        <div class="title">Pászták</div>
        <v-divider vertical></v-divider>
        <div class="button-container">
          <v-btn elevation="0" class="add-btn" @click="openNewParcelModal()">
            <v-icon>mdi-plus-circle</v-icon>
            <span class="add-btn-text">Pászta hozzáadása</span>
          </v-btn>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="card-body">
        <div class="parcel-container">
          <v-sheet
            v-for="(bord, index) in composeBords()"
            @click="openModifyModal(bord)"
            :key="index"
            :class="['parcel-wrapper', 'bordered-1', false && 'bord-center']"
            elevation="0"
          >
            <div v-if="true" class="parcel">
              <table v-if="isEmployeeKickedFromGroup(bord)" class="parcel-table">
                <tbody>
                  <tr :class="[!isEmployeeKicked(row,bord) ? 'td-bg' : 'td-bg-white']" v-for="(row) in rowsCount" :key="row">
                      <div class="mt-tr" v-if="isEmployeeKicked(row,bord)">
                        <div class="x-img">
                        <img height="17" width="17" src="x.svg" alt="">
                        </div>
                      </div>
                      <div class="mt-tr mt-midnum" v-else>
                      {{ getBordRowById(bord).name}}<span v-if="getBordRowById(bord).max_row_number != bord.row_number">{{ '/' + bord.row_number }}</span>
                      </div>
                  </tr>
                 
                </tbody>
              </table>

              <table v-else class="parcel-table mt-bg-gray parcel-center mt-bignum">
                <span>
                  {{getBordRowById(bord).name}}<span v-if="getBordRowById(bord).max_row_number != bord.row_number">{{ '/' + bord.row_number }}</span>
                </span>
              </table>
            </div>

            <div v-else class="bord-center">
              <h2>
                      {{ bord.bord_row_id }}
              </h2>
              </div>
          </v-sheet>
        </div>
      </v-card-text>
    </v-card>
    <NewParcelModal
      :dialog="modals.newParcel.showDialog"
      :groupId="groupId"
      @show-dialog="(value) => (modals.newParcel.showDialog = value)"
      @rows-count="(value) => (modals.newParcel.data.rowsCount = value)"
    />

    <ModifyParcelModal
      @closeModifyModal="modals.modifyParcelModal.showDialog = false"
      :dialog="modals.modifyParcelModal.showDialog"
      :groupId="groupId"
      :selectedParcel="modals.modifyParcelModal.data.selectedParcel"
      @show-dialog="(value) => (modals.modifyParcelModal.showDialog = value)"
      @rows-count="(value) => (modals.newParcel.data.rowsCount = value)"
    />


  </div>
</template>

<script>
import NewParcelModal from "@/components/modals/NewParcel";
import ModifyParcelModal from "@/components/modals/ModifyParcel";
import { mapGetters } from "vuex";
//import store from "@/store";

export default {
  data() {
    return {
      showDialog: false,
      bords: [],
      rowsCount: 3,
      cellsCount: 3,
      modals: {
        newParcel: {
          showDialog: false,
          data: {
            rowsCount: null,
          },
        },
        modifyParcelModal: {
          showDialog: false,
          data: {
            rowsCount: null,
            selectedParcel: {
              id: 1,
              bordrow: {
                id: 1
              }
            },
          }
        }
      },
    };
  },
  mounted() {
    for (let i in this.groups) {
      if (
        this.groups[i].id == this.groupId &&
        typeof this.groups[i].bords !== "undefined" &&
        this.groups[i].bords.length > 0
      ) {
        this.rowsCount = this.groups[i].employees.length;
        this.bords = JSON.parse(JSON.stringify(this.groups[i].bords));
      }
    }

    // console.log("compbords",this.composeBords);
  },
  components: {
    NewParcelModal,
    ModifyParcelModal,
  },
  computed: {
    ...mapGetters({
      event: "returnEvent",
      errors: "returnErrors",
      employees: "returnEmployees",
    }),
    groups() {
      return this.event.groups;
    },

  },
  methods: {
    isEmployeeKickedFromGroup(bord) { // one or more employee is missing
      let isKicked = false;
      let group = this.groups.find(group => group.id == this.groupId);
      // console.log("isKickedGroup",group);
      group.employees.forEach(emp => {
          //if(emp.drop_bords_table_id !== null) isKicked = true;
          if(bord) {
            if(emp.drop_bords_table_id && emp.drop_bords_table_id) {
              if(new Date(emp.drop_time) < new Date(bord.created_at) || emp.drop_bords_table_id == bord.id) {
                isKicked = true;
              }
            }
          }

        
      })
      return isKicked;
    },
    isEmployeeKicked(row,bord) { // is given employee kicked by row
    // console.log("bord",bord);
      let group = this.groups.find(group => group.id == this.groupId);
      if(Object.prototype.hasOwnProperty.call(group.employees[row-1],'drop_bords_table_id') == false) {
        return false
      }
      if(bord) {
        if(group.employees[row-1]?.drop_bords_table_id !== null) {
           if(new Date(group.employees[row-1]?.drop_time) < new Date(bord.created_at) || group.employees[row-1]?.drop_bords_table_id == bord.id) {
            return true;
        } 
        return false;
        }
      }
    },
    openModifyModal(brd) {
      let bordrow = this.event.corn_table.bordrows.find(el => el.id === brd.bord_row_id);
      let bord = {
        ...brd,
        bordrow,
      }
      this.modals.modifyParcelModal.data.selectedParcel = bord;
      this.modals.modifyParcelModal.showDialog = true;
    },
    getBordRowById(bord) {
      let bordrow = this.event.corn_table.bordrows.find(bordrow => bordrow.id === bord.bord_row_id);
      return bordrow;
    },
    composeBords() {
      let bords = []
      for (let i in this.groups) {
        if (this.groups[i].id == this.groupId) {
          this.rowsCount = this.groups[i].employees.length;
          if(Object.prototype.hasOwnProperty.call(this.groups[i], 'bords'))
          bords = JSON.parse(JSON.stringify(this.groups[i]?.bords));
          else bords = []
        }
      }
      return bords;
    },
    openNewParcelModal() {
      this.modals.newParcel.showDialog = true;
    },
  },
  watch: {
    groups: {
      handler(value) {
        //console.log("value:", value);
        for (let i in value) {
          if (value[i].id == this.groupId) {
            this.rowsCount = value[i].employees.length;
            this.bords = JSON.parse(JSON.stringify(value[i]?.bords));
          }
        }
      },
    },
  },
  props: {
    groupId: Number,
  },
};
</script>

<style lang="scss" scoped>
.x-img {
  max-width: 17px;
  height: auto;
  justify-content: center;
  display:flex;
  align-items: center;
}
.mt-bg-gray {
  background-color: rgba(211, 211, 211, 0.5);
}
.mt-bignum {
  font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 56px;
line-height: 84px;
/* or 150% */

text-align: center;

/* Grayscale / Gray 60 (Disabled) */

color: #A1A1A1;
}
.mt-midnum {
  font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 20px;
/* or 111% */

text-align: center;

/* Grayscale / Gray 80 */

color: #656565;
}
.mt-tr {
  display:flex;
  align-items: center;
  justify-content:center;
  height: 100%;
}
.parcel-center {
  display:flex;
  justify-content: center;
  align-items: center;
}
.asd {
height: 100%;
width: 100%;
text-align: center;
}
.bord-center {
  //position:absolute;
  display:flex;
  justify-content:center;
  align-items:center;
}
.parcel-finished {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.wrapper {
  display: contents;
}
.parcel-card {
  .v-divider {
    margin-bottom: 0px !important;
  }
  .card-body {
    .parcel-container {
      overflow: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .parcel-wrapper {
        margin-right: 30px;
        min-height: 150px;
        min-width: 150px;
        .parcel {
          .parcel-table {
            height: 150px;
            width: 100%;
              
            td {
              display:flex;
              background-color: rgba(211, 211, 211, 0.5);
      text-align: center;
            }
          }
          position:relative;
        }
      }
    }

    .td-bg {
      background-color: rgba(211, 211, 211, 0.5);
      text-align: center;
    }

    .td-bg-white {
      background-color: white;
      text-align: center;
    }
  }
}
</style>