<template>
  <v-col class="input-col activity d-flex" cols="12">
    <v-select
      id="activity-select"
      :items="activities"
      :error-messages="validate"
      @change="$v.selectedActivity.$touch()"
      @blur="$v.selectedActivity.$touch()"
      v-model="selectedActivity"
      item-text="name"
      item-value="id"
      label="Tevékenység"
      outlined
    ></v-select>
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  mixins: [validationMixin],
  validations: {
    selectedActivity: { required },
  },
  data() {
    return {
      selectedActivity: null,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      activities: "returnActivities",
    }),
    validate() {
      const errors = [];
      if (!this.$v.selectedActivity.$dirty) return errors;
      !this.$v.selectedActivity.required && errors.push("Kötelező mező");
      return errors;
    },
  },
  watch: {
    selectedActivity: function () {
      this.$emit("activity-input", this.selectedActivity);
    },
    activity: {
      immediate: true,
      handler() {
        this.selectedActivity = this.activity;
      }
    },
  },
  props: ["activity"],
};
</script>

<style>
</style>