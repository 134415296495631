import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import ApiService from '@/services/ApiService'
import { ACTIVITY_ALL, CORNTABLE_ALL, EMPLOYEE_ALL } from "@/apiRoutes";

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        errors: {
            show: false,
            message: ''
        },
        success: {
            show: false,
            message: ''
        },
        corntables: null,
        activities: null,
        employees: null,
        event: {
            id: null,
            corn_table_id: null,
            activity_id: null,
            date: null,
            start_time: null,
            end_time: null,
            created_at: null,
            updated_at: null,
            groups: [],
            corn_table: {
                id: null,
                name: null,
                bord_length: null,
                created_at: null,
                updated_at: null
            }
        },
    },
    getters: {
        returnEvent(state) {
            return state.event
        },
        returnActivities(state) {
            return state.activities
        },
        returnCorntables(state) {
            return state.corntables
        },
        returnEmployees(state) {
            return state.employees
        },
        returnErrors(state) {
            return state.errors
        }
    },
    mutations: {
        setEvent(state, data) {
            state.event = data
            state.event.date = data.date.split(" ")[0];
            state.event.start_time = data.start_time.replace(data.date, "").trim();
            if(data.end_time !== null) state.event.end_time = data.end_time.replace(data.date, "").trim();
            state.event.groups = (Object.prototype.hasOwnProperty.call(data, "groups")) ? data.groups : [];
        },
        setActivity(state, data) {
            state.event.activity_id = data
        },
        /* setGroups(state, data) {
            if(state.event.groups.length == 0) {
                state.event.groups = [];
                state.event.groups.push(data)
            }
            else if (data.type == "Inspector") {
                let foundIns = false;
                for(let i in state.event.groups) {
                    if(state.event.groups[i].type == "Inspector") {
                        foundIns = true;
                        break;
                    }
                }

                if (foundIns == true) {
                    let tempGroup = JSON.parse(JSON.stringify(state.event.groups));

                    for (let i in tempGroup) {
                        if (tempGroup[i].type == "Inspector") {
                            tempGroup[i] = {};
                            tempGroup[i] = JSON.parse(JSON.stringify(data));
                            break;
                        }
                    }

                    state.event.groups = [];
                    state.event.groups = tempGroup;


                } else if (foundIns == false) {
                    state.event.groups.push(data);
                }
            }

            else if (data.type == "Worker") {
                let foundWorker = false;
                for (let i in state.event.groups) {
                    if (state.event.groups[i].type == "Worker") {
                        foundWorker = true;
                    }
                }
                if (foundWorker == true) {
                    let tempGroup = state.event.groups;
                    let foundId = false;

                    for (let i in tempGroup) {
                        if (tempGroup[i].id == data.id) {
                            foundId = true;
                            tempGroup[i] = {};
                            tempGroup[i] = JSON.parse(JSON.stringify(data));
                            break;
                        }
                    }

                    if (foundId == false) {
                        tempGroup.push(data);
                    }

                    state.event.groups = [];
                    state.event.groups = tempGroup;

                } else if (foundWorker == false){
                    //state.event.groups.push(data);
                    //console.log("if no worker group there: ", state.event.groups)
                    let tempGroup = JSON.parse(JSON.stringify(state.event.groups));
                    tempGroup.push(data)

                    state.event.groups = [];
                    state.event.groups = tempGroup;
                }
            }
        }, */
        setControllers(state, data) {
            state.event.groups.push(data);
            console.log('setControllers: ', state.event.groups)
        },
        setWorkers(state, data) {
            state.event.groups.push(data);
            console.log('setWorkers: ', state.event.groups)
        },
        updateControllers(state, data) {
            for(let i in state.event.groups) {
                if(state.event.groups[i]?.type == "Inspector") {
                    if(state.event.groups[i].id == data.id) {
                        state.event.groups[i] = JSON.parse(JSON.stringify(data));
                        console.log(state.event.groups)
                    }
                }
            }
            console.log('updateControllers: ', state.event.groups)
        },
        updateWorkers(state, data) {
            console.log(state.event.groups);
            console.log("data",data);
            //let dataa = JSON.parse(JSON.stringify(data));
             state.event.groups.forEach((group, i) => {
                if(group.type == "Worker") {
                    if(group.id == data.group_id) {
                        group.employees.forEach((employee, j) => {
                            if(employee.id == data.id) {
                                employee.drop_time = data.drop_time;
                                employee.drop_bords_table_id = data.drop_bords_table_id;
                                console.log("emp",employee);
                                state.event.groups[i].employees[j] = employee;
                                console.log("dataid",data.id);
                            }
                        })
                    }
                }
            }); 

            /* for(let i in state.event.groups) {
                if(state.event.groups[i]?.type == "Worker") {
                    if(state.event.groups[i].id == dataa.group_id) {
                        //state.event.groups[i] = JSON.parse(JSON.stringify(data));
                        for (let j in state.event.groups[i].employees) {
                            if(state.event.groups[i].employees[j].id == dataa.id) {
                                console.log("emp",state.event.groups[i].employees[j]);
                                state.event.groups[i].employees[j] = dataa;
                                console.log("newEmp",state.event.groups[i].employees[j])
                            }
                        }
                        console.log(state.event.groups)
                    }
                }
            } */
            console.log('updateWorkers: ', state.event.groups)
        },
        importWorkers(state, data) {
            //data.groups.forEach(gr => state.event.groups.push(gr));
            //state.event.groups = data;
            data.forEach(group => {
                group.bords = [];
                state.event.groups.push(group);
            })
        },
        setBords(state, data) {
            for(let i in state.event.groups) {
                if(state.event.groups[i].id == data.group_id) {
                    if(Object.prototype.hasOwnProperty.call(state.event.groups[i], "bords") == true) {
                        let tempBords = JSON.parse(JSON.stringify(state.event.groups[i].bords));

                        tempBords.unshift(data);


                        state.event.groups[i].bords = [];
                        state.event.groups[i].bords = tempBords;

                    } else {
                        state.event.groups[i].bords = [];
                        state.event.groups[i].bords.push(data);
                    }
                    
                }
            }
            //console.log(state.event.groups);
        },
        updateBord(state,data) {
            console.log(data.id);
            state.event.groups.forEach(group => {
                if(group.id == data.group_id) {
                    group.bords.forEach(bord => {
                        if(bord.id == data.id) {
                            bord.penalty = data.penalty;
                            bord.row_number = data.row_number;
                        }
                    })
                }
            })
        },
        setActivities(state, data) {
            state.activities = data
        },
        setCorntables(state, data) {
            state.corntables = data
        },
        setEmployees(state, data) {
            state.employees = data
        },
        setErrors(state, data) {
            state.errors.show = data.show
            state.errors.message = data.message
        },
        setSuccess(state, data) {
            state.success.show = data.show
            state.success.message = data.message
        },
        deleteGroup(state, data) {
            for (let i in state.event.groups) {
                if (state.event.groups[i].id == data) {
                    state.event.groups.splice(i, 1);
                }
            }
        },
    },
    actions: {
        getCorntables({ commit }) {
            ApiService(CORNTABLE_ALL)
                .get()
                .then((res) => {
                    if (Array.isArray(res.data)) {
                        // console.log(res.data);
                        commit('setCorntables', res.data)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getActivities({ commit }) {
            ApiService(ACTIVITY_ALL)
                .get()
                .then((res) => {
                    if (Array.isArray(res.data)) {
                        commit('setActivities', res.data)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getEmployees({ commit }) {
            ApiService(EMPLOYEE_ALL)
                .get()
                .then((res) => {
                    if (Array.isArray(res.data)) {
                        commit('setEmployees', res.data)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
})