<template>
  <v-dialog class="modal" v-model="showDialog" width="500" persistent>
    <v-card class="modal-card">
      <v-card-title class="card-header">
        Ellenőrök és tevékenység szerkesztése
        <div class="close-container" @click="showDialog = false">
          <v-icon class="close-icon">mdi-close</v-icon>
        </div>
      </v-card-title>
      <v-card-text class="card-body">
        <v-row align="center" class="input-row">
          <v-col class="input-col newresource d-flex" cols="12">
            <v-autocomplete
              v-model="selectedEmployee"
              :items="employees"
              :item-value="(item) => item"
              :item-text="(item) => item.firstname + ' ' + item.lastname"
              label="Emberek hozzáadása"
              prepend-inner-icon="mdi-magnify"
              outlined
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row align="center" class="input-row">
          <v-col>
            <v-chip-group show-arrows class="chip-container">
              <v-chip
                v-for="person in input.controllers"
                :key="person.id"
                label
                close
                close-icon="mdi-close"
                @click:close="removeController(person)"
                >{{ person.firstname }} {{ person.lastname }}</v-chip
              >
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row align="center" class="input-row">
          <ActivitySelect
            :activity="this.input.activity"
            @activity-input="(value) => (this.input.activity = value)"
          />
        </v-row>

        <v-divider></v-divider>
      </v-card-text>

      <v-card-actions class="card-footer">
        <v-spacer></v-spacer>
        <div class="buttons-container">
          <v-btn
            color="primary"
            elevation="2"
            block
            x-large
            class="save-btn"
            @click="save()"
          >
            <span class="save-btn-text">Mentés</span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ActivitySelect from "@/components/ActivitySelect";
import { ADD_GROUP, UPDATE_GROUP, UPDATE_EVENT } from "@/apiRoutes";
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  data() {
    return {
      showDialog: false,
      selectedEmployee: null,
      input: {
        controllers: [],
        activity: null,
      },
      apiError: {
        show: false,
        message: "",
      },
    };
  },
  components: {
    ActivitySelect,
  },
  mounted() {
    this.input.activity = this.event.activity_id;
  },
  computed: {
    ...mapGetters({
      employees: "returnEmployees",
      event: "returnEvent",
    }),
  },
  methods: {
    removeController(person) {
      if (this.input.controllers.includes(person)) {
        let index = this.input.controllers.indexOf(person);
        this.input.controllers.splice(index, 1);
      }
    },
    updateActivity(data, queryParams) {
      this.ApiService(UPDATE_EVENT, data, queryParams)
        .put()
        .then((res) => {
          if (typeof res.data != "undefined" && res.data !== null) {
            store.commit("setActivity", res.data.activity_id);
            this.showDialog = false;
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "Az ellenőrök törlése sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    updateInspector(data, queryParams) {
      this.ApiService(UPDATE_GROUP, data, queryParams)
        .put()
        .then((res) => {
          if (typeof res.data != "undefined" && res.data !== null) {
            store.commit("updateControllers", res.data);
            this.showDialog = false;
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "Az ellenőrök frissítése sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    addInspector(data, queryParams) {
      this.ApiService(ADD_GROUP, data, queryParams)
        .post()
        .then((res) => {
          if (typeof res.data != "undefined" && res.data !== null) {
            store.commit("setControllers", res.data);
            this.showDialog = false;
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "Az ellenőrök törlése sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    composeControllers() {
      let employees = [];

      for (let person in this.input.controllers) {
        employees.push({
          employee_id: this.input.controllers[person].id,
        });
      }

      return {
        type: "Inspector",
        employees: employees,
      };
    },
    save() {
      if (this.input.controllers?.length > 0) {
        const data = this.composeControllers();

        if (this.input.activity !== this.event.activity_id) {
          const eventData = {
            activity_id: this.input.activity,
            date: this.event.date,
            start_time: this.event.date + " " + this.event.start_time,
            end_time: this.event.date + " " + this.event.end_time,
            corn_table_id: this.event.corn_table_id,
          };

          const eventParams = {
            event_id: this.event.id,
          };

          this.updateActivity(eventData, eventParams);
        }
        
        let found = false;
        for(let i in this.event.groups) {
          
          if(this.event.groups[i].type == "Inspector") {
            found = true;
            break
          }
        }
        if (found == false) {
          const queryParams = {
            event_id: this.event.id,
          };

          this.addInspector(data, queryParams);
        } else if (this.event.groups?.length > 0) {
          let group_id = null;

          for (let i in this.event.groups) {
            if (this.event.groups[i].type == "Inspector") {
              group_id = this.event.groups[i].id;
            }
          }

          const queryParams = {
            group_id: group_id,
          };
          this.updateInspector(data, queryParams);
        }
      } else {
        this.apiError.show = true;
        this.apiError.message = "Nem adtál meg embereket";

        let vm = this;
        setTimeout(() => {
          vm.apiError.show = false;
          vm.apiError.message = "";
        }, 5000);
      }
    },
  },
  watch: {
    dialog: function () {
      this.input.controllers = JSON.parse(
        JSON.stringify(this.selectedControllers)
      );
      this.selectedEmployee = null;
      this.showDialog = this.dialog;
    },
    showDialog: function () {
      this.$emit("show-dialog", this.showDialog);
    },
    selectedEmployee: function (value) {
      if (value !== null) {
        var found = false;

        for (var i = 0; i < this.input.controllers.length; i++) {
          if (this.input.controllers[i].id == value.id) {
            found = true;
            break;
          }
        }

        if (!found) {
          this.input.controllers.push(value);
        }
      }
    },
    apiError: {
      deep: true,
      handler(value) {
        this.$emit("error-alert", value);
      },
    },
  },
  props: {
    dialog: Boolean,
    selectedControllers: Array,
  },
};
</script>

<style lang="scss">
.modal-card {
  .card-body {
    .newresource {
      padding-bottom: 0px;
    }
    .activity {
      //padding-top: 30px !important;
    }
    .chip-container {
      margin-bottom: 30px;
    }
  }
}
</style>