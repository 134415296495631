<template>
  <div class="content-wrapper">
    <v-alert
      id="dashboard-alert"
      :value="errors.show"
      class="error-alert"
      type="error"
      transition="scale-transition"
      dismissible
    >{{ errors.message }}</v-alert>

    <v-alert
      id="dashboard-alert"
      :value="apiSuccess.show"
      class="success-alert"
      type="success"
      transition="scale-transition"
      dismissible
      >{{ apiSuccess.message }}</v-alert
    >
    <div class="heading">
      <div>
        <v-icon class="back-icon" @click="goBack()">mdi-arrow-left</v-icon>
      </div>
      <DomboTitle />
      <div>
        <v-icon class="reload-icon" @click="reload()">mdi-reload</v-icon>
      </div>
    </div>
    <div class="options-container">
      <div class="input-wrapper">
        <div class="input-row">
          <TableSelect
            :table="input.table"
            @corntable-input="(value) => (input.table = value)"
            :isDisabled="true"
          />
          <DatePicker
            :date="input.date"
            :isTime="true"
            :timeInterval="input.timeInterval"
            @date-input="
              (date, timeInterval) => {
                (this.input.date = date),
                  (this.input.timeInterval.start = timeInterval.start),
                  (this.input.timeInterval.end = timeInterval.end);
              }
            "
          />
          <v-btn
            id="filter"
            class="filter-btn bg-black color-white"
            @click="editDay"
          >
            Adatok mentése
          </v-btn>
        </div>
      </div>
      <div class="input-wrapper">
        <div class="input-row">
          <ActivityAndControllers
            :activity="input.activity"
            :dialog="modals.editControllers"
            @selected-controllers="(value) => (this.input.controllers = value)"
            @show-dialog="(value) => (this.modals.editControllers = value)"
          />
        </div>
      </div>
    </div>
    <div class="buttons-container">
      <v-btn
        color="primary"
        elevation="2"
        class="add-btn"
        @click="modals.newResource = true"
      >
        <v-icon>mdi-plus</v-icon>
        <span class="add-btn-text">Emberek hozzáadása</span>
      </v-btn>
      <v-btn elevation="0" class="export-btn bordered-1" @click="endDay()">
        <v-icon>mdi-key</v-icon>
        <span class="export-btn-text">Tábla zárása</span>
      </v-btn>
      <v-btn elevation="0" class="export-btn bordered-1" @click="downloadPdf()">
        <v-icon>mdi-download</v-icon>
        <span class="export-btn-text">Nap exportálása</span>
      </v-btn>
    </div>
    <div class="workbench-container">
      <div class="item-row">
        <div
          
          v-for="(worker_group, index) in groups.filter(gr => gr.type == 'Worker')"
          :key="index"
        >
        <div v-if="!worker_group.isOld" class="item-wrapper">
            <TeamCard  :index="index" :bords="worker_group.bords" :employeees="worker_group.employees" :groupId="worker_group.id" />
             <ParcelCard :groupId="worker_group.id" />
        </div>
        </div>
      </div>
    </div>
    <EditControllersModal
      :dialog="modals.editControllers"
      :selectedControllers="input.controllers"
      @show-dialog="(value) => (modals.editControllers = value)"
      @error-alert="(value) => (apiError = value)"
    />
    <NewResourceModal
      :dialog="modals.newResource"
      @show-dialog="(value) => (modals.newResource = value)"
    />
  </div>
</template>

<script>
import DomboTitle from "@/components/DomboTitle";
import TableSelect from "@/components/TableSelect";
import DatePicker from "@/components/DatePicker";
import ActivityAndControllers from "@/components/ActivityAndControllers";
import TeamCard from "@/components/TeamCard";
import ParcelCard from "@/components/ParcelCard";
import NewResourceModal from "@/components/modals/NewResource";
import EditControllersModal from "@/components/modals/EditControllers";
import { mapGetters } from "vuex";
import { UPDATE_EVENT, SEARCH_EVENT, DOWNLOAD_PDF, END_EVENT } from "@/apiRoutes";
import store from "@/store";

export default {
  data() {
    return {
      workerGroups: [],
      modals: {
        newResource: false,
        editControllers: false,
      },
      input: {
        controllers: [],
        table: null,
        date: null,
        activity: null,
        timeInterval: {
          start: null,
          end: null,
        },
      },
      apiError: {
        show: false,
        message: "",
      },
      apiSuccess: {
          show: false,
          message: "",
        },
    };
  },
  components: {
    DomboTitle,
    TableSelect,
    DatePicker,
    ActivityAndControllers,
    TeamCard,
    ParcelCard,
    NewResourceModal,
    EditControllersModal,
  },
  created() {},
  mounted() {
    this.apiError.show = false;
    this.apiError.message = "";

    store.commit("setErrors", this.apiError);
  },
  computed: {
    ...mapGetters({
      event: "returnEvent",
      errors: "returnErrors",
    }),
    groups() {
      // console.log("groups")
      // console.log(this.event.groups);
      return this.event.groups;
    },

  },
  methods: {
    downloadPdf() {
      const queryParams = {
        date: this.event.date,
      };

      this.ApiService(DOWNLOAD_PDF, {}, queryParams, {headers: { 'Authorization': `Bearer ${localStorage.token}` }})
        .download()
        .then((res) => {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "file.pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        });
    },
    endDay() {
      const queryParams = {
        id: this.event.id,
      };

      this.ApiService(END_EVENT, {}, queryParams)
        .get()
        .then((res) => {
          if (typeof res.data != "undefined" && res.data !== null) {
              this.apiSuccess.show = true;
              this.apiSuccess.message = "Sikeresen lezártuk a napot";

              store.commit("setSuccess", this.apiSuccess);

              let self = this;
              setTimeout(() => {
                self.apiSuccess.show = false;
                self.apiSuccess.message = "";

                store.commit("setSuccess", this.apiSuccess);
              }, 5000);
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "Sikertelen táblazárás";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    updateEvent(data, queryParams) {
      this.ApiService(UPDATE_EVENT, data, queryParams)
        .put()
        .then((res) => {
          if (typeof res.data != "undefined" && res.data !== null) {
            console.log("OPENED EVENT: ", res);
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "Az adatok mentése sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    editDay() {
      const data = {};

      const queryParams = {
        id: this.event.id,
      };

      this.updateEvent(data, queryParams);
    },
    searchEvent(queryParams) {
      this.ApiService(SEARCH_EVENT, {}, queryParams)
        .get()
        .then((res) => {
          if (typeof res.data != "undefined" && res.data !== null) {
            store.commit("setEvent", res.data);
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "A frissítés sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    reload() {
      let btn = document.getElementsByClassName("reload-icon");
      btn[0].classList.add("rotating");

      setTimeout(() => {
        btn[0].classList.remove("rotating");
      }, 1000);

      const queryParams = {
        corntable_id: this.event.corn_table_id,
        date: this.event.date,
      };

      this.searchEvent(queryParams);
    },
    goBack() {
      this.$router.push("/nap");
    },
    composeWorkerGroups(groups) {
      this.workerGroups = [];
  console.log("asdsads");
      for (let i in groups) {
        if (groups[i].type == "Worker") {
          this.workerGroups.push(groups[i]);
        }
      }
      console.log("workergroups");
      console.log(this.workerGroups);
      return this.workerGroups;
    },
  },
  watch: {
    event: {
      immediate: true,
      handler(value) {
        if (value.corn_table_id == null) return;
        this.input.timeInterval.start = value.start_time
          .replace(value.date, "")
          .trim();
        if(value.end_time != null) {
          this.input.timeInterval.end = value.end_time
          .replace(value.date, "")
          .trim();
        }
        this.input.table = value.corn_table_id;
        this.input.date = value.date;
        this.input.activity = value.activity_id;
        // console.log("Dash event watch handler")
      },
    },
    actualWorkers(newVal) {
      console.log("acutalWnewVal", newVal);
    },

    groups: {
      immediate: true,
      handler() {
        // console.log("Dash, groupd handler runs")
        //this.composeWorkerGroups(value);
      },
    },
  },
};
</script>

<style lang="scss">
.content-wrapper {
  padding: 40px 50px;
  #dashboard-alert {
    position: fixed;
    right: 50px;
    z-index: 1000;
    width: 376px;
  }
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .reload-icon,
    .back-icon {
      cursor: pointer;
    }
    .reload-icon {
      transform: rotate(0deg);
      transition: transform 0.4s linear;
    }
    @keyframes reloadAnim {
      from {
        transform: rotate(-360deg);
      }
      to {
        transform: rotate(0); /* read ahead for alternate animation */
      }
    }
    .rotating {
      animation: reloadAnim 0.4s linear;
    }
  }
  .options-container {
    padding-top: 50px;
    padding-bottom: 30px;
    .input-wrapper {
      display: block;
      width: 100%;
      .input-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-bottom: 0px;
        .filter-btn {
          min-height: 56px !important;
        }
        .table-name,
        .date {
          padding: 0px !important;
        }
        .table-name {
          flex: 0 0 60%;
        }
        .date {
          flex: 0 0 27%;
        }

        .activity-controller-row {
          width: 100%;
          padding: 0 12px;
          display: flex;
          align-items: center;
          border-radius: 4px;
          background-color: white;
          min-height: 56px;
          .v-divider--vertical {
            margin: 0px 10px;
            align-self: unset !important;
            min-height: 80% !important;
            max-height: 80% !important;
          }
          .activity-container {
            white-space: nowrap;
          }
          .controller-container {
            height: 100%;
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: flex-start;
            .controller-text {
              margin-right: 10px;
            }
            .controller-chips {
              //margin-left: auto;
            }
            @media only screen and (max-width: 768px) {
              .controller-chips {
                .v-slide-group__content {
                  display: block;
                  white-space: normal;
                }
                //max-width: 200px;
              }
            }
            @media only screen and (min-width: 768px) {
              .controller-chips {
                max-width: 300px;
              }
            }
            @media only screen and (min-width: 1024px) {
              .controller-chips {
                max-width: 550px;
              }
            }
            @media only screen and (min-width: 1366px) {
              .controller-chips {
                max-width: 900px;
              }
            }
          }
          .edit-container {
            width: 45px;
            margin-left: auto;
            display: flex;

            height: 100%;
            align-items: center;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .input-row {
      flex-direction: column;
      .filter-btn {
        margin-bottom: 30px;
        max-width: 200px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .controller-container {
      flex-direction: column;
    }
    .controller-chips {
      max-width: 550px !important;
    }
    .activity-controller-row {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      flex-direction: column !important;
    }
    .title-container {
      width: 100%;
      text-align: center;
    }
    .filter-btn {
      margin-bottom: 30px;
      max-width: unset !important;
    }
    .buttons-container {
      flex-direction: column;
      .add-btn {
        margin-bottom: 30px;
      }
    }
    .workbench-container {
      .item-wrapper {
        flex-direction: column;
        .team {
          margin-bottom: 30px;
        }
        .parcel-card {
          flex: 0 0 100% !important;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1366px) {
  }

  .buttons-container {
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    .add-btn,
    .export-btn {
      height: 56px;
      .add-btn-text,
      .export-btn-text {
        margin-left: 5px;
      }
    }
    .export-btn {
      background-color: rgba(211, 211, 211, 0.5);
    }
  }
  .workbench-container {
    .v-divider {
      margin-bottom: 30px;
    }
    .item-wrapper {
      display: flex;
      margin-bottom: 30px;
      height: 260px;
      width: 100%;
      justify-content: space-between;
      .team {
        .v-divider {
          margin-bottom: 0px;
        }
        padding: 12px;
        flex: 0 0 27% !important;
        .card-header {
          display: flex;
          justify-content: space-between;
          padding: 0 0 6px 0;
          .title {
            font-weight: normal;
          }
        }
        .card-body {
          padding: 0px;
          .name-container {
            .name-text {
              border-radius: 4px;
              background-color: rgba(211, 211, 211, 0.5);
              padding: 12px;
              margin: 16px 0 16px 0;
            }
          }
        }
      }
      .parcel-card {
        overflow-x: hidden;
        overflow-y: hidden;
        padding: 12px;
        flex: 0 0 70%;
        .card-header {
          .v-divider {
            margin: 0 10px 0 16px;
          }
          display: flex;
          padding: 0 0 6px 0;
          .title {
            font-weight: normal;
          }
          .button-container {
            .add-btn {
              padding: 0 6px 0 6px;
              background-color: transparent;
            }
            .add-btn-text {
              padding-left: 6px;
            }
          }
        }
      }
    }
  }
}
</style>