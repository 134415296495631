<template>
  <v-col class="input-col date d-flex" cols="12">
    <v-dialog
      ref="datepicker"
      id="datepicker-menu"
      class="datepicker-menu"
      v-model="datepicker"
      :close-on-content-click="false"
      transition="scale-transition"
      top
      fixed
      max-width="376"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          id="datepicker-input"
          class="datepicker-input"
          v-model="selectedDate"
          :error-messages="validate"
          @change="$v.selectedDate.$touch()"
          @blur="$v.selectedDate.$touch()"
          background-color="white"
          label="Dátum és idő"
          append-icon="mdi-calendar"
          readonly
          outlined
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-card class="datepicker-container">
        <v-date-picker
          id="datepicker"
          class="datepicker"
          width="100%"
          v-model="selectedDate"
          color="primary"
          no-title
        ></v-date-picker>
        <v-divider></v-divider>
        <div class="timepicker" v-if="isTime">
          <v-icon>mdi-clock-time-five-outline</v-icon>

          <div class="input-container">
            <v-combobox
              id="start-time"
              class="start-time"
              :items="time"
              label="Kezdés"
              v-model="selectedTimeInterval.start"
              single-line
              :menu-props="{
                maxHeight: 90,
                offsetY: true,
              }"
            ></v-combobox>
            <span class="long-dash">&#8212;</span>
            <v-combobox
              id="end-time"
              class="end-time"
              :items="time"
              label="Vége"
              v-model="selectedTimeInterval.end"
              single-line
              :menu-props="{
                maxHeight: 90,
                offsetY: true,
              }"
            ></v-combobox>
          </div>
        </div>

        <v-card-actions class="card-footer">
          <v-btn elevation="2" class="cancel-btn bordered-1" @click="cancel()">
            Mégsem
          </v-btn>
          <v-btn color="primary" elevation="2" class="ok-btn" @click="save()">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    selectedDate: { required },
    selectedTimeInterval: {
      start: {},
      end: {}
    }
  },
  data() {
    return {
      time: [],
      selectedTimeInterval: {
        start: null,
        end: null,
      },
      selectedDate: null,
      datepicker: false,
    };
  },
  created() {
    this.selectedDate = this.date
    this.selectedTimeInterval = JSON.parse(JSON.stringify(this.timeInterval));
    this.fillTimeArray();
  },
  mounted() {},
  computed: {
    validate() {
      const errors = [];
      if (!this.$v.selectedDate.$dirty) return errors;
      !this.$v.selectedDate.required && errors.push("Kötelező mező");
      return errors;
    },
  },
  methods: {
    fillTimeArray() {
      this.time = [];
      var i, j;
      for (i = 0; i < 24; i++) {
        for (j = 0; j < 4; j++) {
          this.time.push(i + ":" + (j === 0 ? "00" : 15 * j));
        }
      }
    },
    cancel() {
      this.selectedDate = this.date;
      this.selectedTimeInterval = JSON.parse(JSON.stringify(this.timeInterval));
      this.datepicker = false;
    },
    save() {
      this.datepicker = false;
      this.$emit("date-input", this.selectedDate, this.selectedTimeInterval);
    },
  },
  watch: {
    date: function () {
      this.selectedDate = this.date;
    },
    timeInterval: function () {
      this.selectedTimeInterval = JSON.parse(JSON.stringify(this.timeInterval));
    },
  },
  props: ["date", "timeInterval", "isTime"],
};
</script>
