<template>
<div class="content-wrapper">
<div class="heading">
    <DomboTitle />
</div>
<div class="not-found">
    <div class="icon">
        <v-icon size="120">mdi-emoticon-sad-outline</v-icon>
    </div>
    <div class="code">404</div>
    <div class="text">Az oldal nem található</div>
    <div class="go" @click="goToFinder"><v-icon>mdi-arrow-right</v-icon> Nap megnyitása</div>
</div>
</div>

</template>

<script>
import router from '@/router'
import DomboTitle from '@/components/DomboTitle'
export default {
    components: {
        DomboTitle
    },
    methods: {
        goToFinder() {
            router.replace('/nap')
        }
    }
}
</script>

<style lang="scss" scoped>
 .content-wrapper {
  padding: 40px 50px;
  .heading {
    display: flex;
  }
  .not-found{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .text, .code{
        display: block;
    }
    .code{
        color: grey;
        font-weight: 500;
        font-size: 72px;
    }
    .text{
        font-weight: 300;
        color: grey;
        text-align: center;
        font-size: 48px;
    }
    .go {
        cursor: pointer;
    }
  }
 }
</style>