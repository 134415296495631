<template>
  <v-dialog class="modal" v-model="showDialog" width="400" persistent>
    <v-card>
      <v-card-title class="card-header" style="text-align:center">Biztosan törölni szeretnéd ezt a csapatot?</v-card-title>
      <v-divider></v-divider>
      <v-card-actions class="card-footer">
        <div class="buttons-container">
          <v-btn
            elevation="2"
            class="cancel-btn bordered-1"
            x-large
            @click="() => (this.showDialog = false)"
          >
            <span class="cancel-btn-text">Nem</span>
          </v-btn>
          <v-btn
            color="primary"
            elevation="2"
            x-large
            class="confirm-btn"
            @click="confirm()"
          >
            <span class="confirm-btn-text">Igen</span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    confirm() {
      console.log("confirmed")
      this.requestMethod();
    },
  },
  watch: {
    dialog: function () {
      this.showDialog = this.dialog;
    },
    showDialog: function () {
      this.$emit("show-dialog", this.showDialog);
    },
  },
  props: {
    dialog: Boolean,
    requestMethod: Function,
  },
};
</script>

<style lang="scss" scoped>
.v-card{
    padding: 0 24px 20px;
}
.v-divider{
    margin-bottom: 20px;
}
.card-header{
    justify-content: center;
}
.card-footer{
    padding: 0px !important;
}

.buttons-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .confirm-btn, .cancel-btn{
        width: 45%;
    }
    .cancel-btn{
        background-color: white !important;
    }
}
</style>