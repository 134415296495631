<template>
  <v-col class="input-col table-name d-flex" cols="12">
    <v-select
      id="corntable-select"
      :items="corntables"
      :error-messages="validate"
      @change="$v.selectedTable.$touch()"
      @blur="$v.selectedTable.$touch()"
      v-model="selectedTable"
      background-color="white"
      item-text="name"
      item-value="id"
      label="Tábla neve"
      outlined
      :disabled="isDisabled"
    >
    </v-select>
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  mixins: [validationMixin],
  validations: {
    selectedTable: { required },
  },
  data() {
    return {
      selectedTable: null,
    };
  },
  created() {
    if (typeof this.table != "undefined" && this.table != null)
      this.selectedTable = this.table;
  },
  computed: {
    ...mapGetters({
      corntables: "returnCorntables",
    }),
    validate() {
      const errors = [];
      if (!this.$v.selectedTable.$dirty) return errors;
      !this.$v.selectedTable.required && errors.push("Kötelező mező");
      return errors;
    },
  },
  watch: {
    selectedTable: function () {
      // console.log("selectedTabe", this.selectedTable);
      this.$emit("corntable-input", this.selectedTable);
    },
    table: function () {
      this.selectedTable = this.table;
    },
  },
  props: ["table", "isDisabled"],
};
</script>

<style>
</style>