<template>
  <div class="content-wrapper d-flex align-center justify-center">
    <div class="">
      <v-card elevation="8" class="finder-card rounded-0 bordered-1" width="450" height="500">
        <v-alert id="finder-alert" :value="apiError.show" class="error-alert" type="error" transition="scale-transition"
          dismissible style="margin: -5px 17px; border-radius: 5px;">{{ apiError.message }}</v-alert>
        <v-alert id="finder-alert" :value="apiSuccess.show" class="error-alert" type="success"
          transition="scale-transition" dismissible style="margin: -5px 17px; border-radius: 5px;">
          {{ apiSuccess.message }}</v-alert>
        <v-card-title class="card-header d-flex justify-center">
          <DomboTitle />
        </v-card-title>
        <v-card-text class="card-body text-center">
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="email" label="Email" type="email" outlined clearable autofocus></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="password" label="Jelszó" type="password" outlined clearable></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="card-footer">
          <v-btn block color="primary" elevation="2" x-large class="open-btn" @click="login()">
            Bejelentkezés
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
  import DomboTitle from "@/components/DomboTitle";
  import {
    LOGIN
  } from "@/apiRoutes";
  import store from "@/store";

  export default {
    model: {},

    data() {
      return {
        email: null,
        password: null,
        apiError: {
          show: false,
          message: "",
        },
        apiSuccess: {
          show: false,
          message: "",
        },
      }
    },
    components: {
      DomboTitle,
    },
    mounted() {
      localStorage.token = null
    },
    methods: {
      login() {
        const data = {
          email: this.email,
          password: this.password
        }

        this.loginRoute(data)
      },

      loginRoute(data) {
        this.ApiService(LOGIN, data)
          .post()
          .then((res) => {
            if (typeof res.data != "undefined" && res.data !== null) {
              localStorage.token = res.data.access_token

              this.apiSuccess.show = true;
              this.apiSuccess.message = "Sikeres bejelentkezés";

              store.commit("setSuccess", this.apiSuccess);

              let success = this;
              setTimeout(() => {
                success.apiSuccess.show = false;
                success.apiSuccess.message = "";

                store.commit("setSuccess", this.apiSuccess);
                this.$router.push('nap');
              }, 1000);
            }
          })
          .catch(() => {
            this.apiError.show = true;
            this.apiError.message = "Sikertelen bejelentkezés";

            store.commit("setErrors", this.apiError);

            let vm = this;
            setTimeout(() => {
              vm.apiError.show = false;
              vm.apiError.message = "";

              store.commit("setErrors", this.apiError);
            }, 3000);
          });
      },
    }
  };
</script>