var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"input-col date d-flex",attrs:{"cols":"12"}},[_c('v-dialog',{ref:"datepicker",staticClass:"datepicker-menu",attrs:{"id":"datepicker-menu","close-on-content-click":false,"transition":"scale-transition","top":"","fixed":"","max-width":"376","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"datepicker-input",attrs:{"id":"datepicker-input","error-messages":_vm.validate,"background-color":"white","label":"Dátum és idő","append-icon":"mdi-calendar","readonly":"","outlined":""},on:{"change":function($event){return _vm.$v.selectedDate.$touch()},"blur":function($event){return _vm.$v.selectedDate.$touch()}},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datepicker),callback:function ($$v) {_vm.datepicker=$$v},expression:"datepicker"}},[_c('v-card',{staticClass:"datepicker-container"},[_c('v-date-picker',{staticClass:"datepicker",attrs:{"id":"datepicker","width":"100%","color":"primary","no-title":""},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}}),_c('v-divider'),(_vm.isTime)?_c('div',{staticClass:"timepicker"},[_c('v-icon',[_vm._v("mdi-clock-time-five-outline")]),_c('div',{staticClass:"input-container"},[_c('v-combobox',{staticClass:"start-time",attrs:{"id":"start-time","items":_vm.time,"label":"Kezdés","single-line":"","menu-props":{
              maxHeight: 90,
              offsetY: true,
            }},model:{value:(_vm.selectedTimeInterval.start),callback:function ($$v) {_vm.$set(_vm.selectedTimeInterval, "start", $$v)},expression:"selectedTimeInterval.start"}}),_c('span',{staticClass:"long-dash"},[_vm._v("—")]),_c('v-combobox',{staticClass:"end-time",attrs:{"id":"end-time","items":_vm.time,"label":"Vége","single-line":"","menu-props":{
              maxHeight: 90,
              offsetY: true,
            }},model:{value:(_vm.selectedTimeInterval.end),callback:function ($$v) {_vm.$set(_vm.selectedTimeInterval, "end", $$v)},expression:"selectedTimeInterval.end"}})],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"card-footer"},[_c('v-btn',{staticClass:"cancel-btn bordered-1",attrs:{"elevation":"2"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Mégsem ")]),_c('v-btn',{staticClass:"ok-btn",attrs:{"color":"primary","elevation":"2"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Ok ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }