<template>
  <div class="content-wrapper d-flex align-center justify-center">
    <v-alert
      id="finder-alert"
      :value="apiError.show"
      class="error-alert"
      type="error"
      transition="scale-transition"
      dismissible
      >{{ apiError.message }}</v-alert
    >
    <div class="">
      <v-card
        elevation="8"
        class="finder-card rounded-0 bordered-1"
        width="450"
        height="620"
      >
        <v-card-title class="card-header d-flex justify-center">
          <DomboTitle />
        </v-card-title>
        <v-card-text class="card-body text-center">
          <v-tabs
            class="tabs"
            centered
            grow
            color="black"
            v-model="tab"
            @change="clearInputValues()"
          >
            <v-tab class="tab d-flex">
              <v-icon>mdi-open-in-new</v-icon>
              <span>Megnyitás</span>
            </v-tab>
            <v-tab class="tab">
              <v-icon>mdi-magnify</v-icon>
              <span>Keresés</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-container class="form-container" fluid>
                <v-row align="center" class="input-row">
                  <TableSelect
                    ref="tableSelect_Open"
                    :table="this.input.table"
                    @corntable-input="(value) => (this.input.table = value)"
                  />
                </v-row>
                <v-row align="center" class="input-row">
                  <DatePicker
                    ref="datePicker_Open"
                    :date="this.input.date"
                    :isTime="true"
                    :timeInterval="this.input.timeInterval"
                    @date-input="
                      (date, timeInterval) => {
                        (this.input.date = date),
                          (this.input.timeInterval = timeInterval);
                      }
                    "
                  />
                </v-row>
                <v-row align="center" class="input-row">
                  <ActivitySelect
                    ref="activitySelect_Open"
                    :activity="this.input.activity"
                    @activity-input="(value) => (this.input.activity = value)"
                  />
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container class="form-container" fluid>
                <v-row align="center" class="input-row">
                  <TableSelect
                    ref="tableSelect_Search"
                    :table="this.input.table"
                    @corntable-input="(value) => (this.input.table = value)"
                  />
                </v-row>
                <v-row align="center" class="input-row">
                  <DatePicker
                    ref="datePicker_Search"
                    :isTime="false"
                    :date="this.input.date"
                    :timeInterval="this.input.timeInterval"
                    @date-input="
                      (date, timeInterval) => {
                        (this.input.date = date),
                          (this.input.timeInterval = timeInterval);
                      }
                    "
                  />
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions class="card-footer">
          <v-btn
            block
            color="primary"
            elevation="2"
            x-large
            class="open-btn"
            @click="openDay()"
          >
            Nap megnyitása
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import DomboTitle from "@/components/DomboTitle";
import DatePicker from "@/components/DatePicker";
import TableSelect from "@/components/TableSelect";
import ActivitySelect from "@/components/ActivitySelect";
import { CREATE_EVENT, SEARCH_EVENT } from "@/apiRoutes";
import store from "@/store";

export default {
  data() {
    return {
      input: {
        table: null,
        date: null,
        timeInterval: {
          start: null,
          end: null,
        },
        activity: null,
      },
      tab: null,
      apiError: {
        show: false,
        message: "",
      },
    };
  },
  created() {
    store.dispatch('getActivities')
    store.dispatch('getCorntables')
    store.dispatch('getEmployees')
  },
  components: {
    DomboTitle,
    DatePicker,
    TableSelect,
    ActivitySelect,
  },
  computed: {
    selectErrors() {
      const errors = [];
      if (!this.$v.select.$dirty) return errors;
      !this.$v.select.required && errors.push("Kötelező mező");
      return errors;
    },
  },
  methods: {
    createEvent(data) {
      this.ApiService(CREATE_EVENT, data)
        .post()
        .then((res) => {
          if (typeof res.data != "undefined" && res.data !== null) {
            store.commit("setEvent", res.data);
            this.$router.push("/kezdolap");
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "A nap megnyitása sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    searchEvent(queryParams) {
      this.ApiService(SEARCH_EVENT, {}, queryParams)
        .get()
        .then((res) => {
          let data = JSON.parse(JSON.stringify(res.data));
          if (typeof res.data != "undefined" && res.data !== null) {
            store.commit("setEvent", data);
            this.$router.push("/kezdolap");
          }
        })
        .catch((error) => {
          console.log(error);

          this.apiError.show = true;
          this.apiError.message = "A nap megnyitása sikertelen";

          store.commit("setErrors", this.apiError);

          let vm = this;
          setTimeout(() => {
            vm.apiError.show = false;
            vm.apiError.message = "";

            store.commit("setErrors", this.apiError);
          }, 5000);
        });
    },
    openDay() {
      if (this.tab == 0) {
        this.$refs.tableSelect_Open.$v.selectedTable.$touch();
        this.$refs.datePicker_Open.$v.selectedDate.$touch();
        this.$refs.activitySelect_Open.$v.selectedActivity.$touch();

        const data = {
          corn_table_id: this.input.table,
          activity_id: this.input.table,
          date: this.input.date,
          start_time: this.input.date + " " + this.input.timeInterval.start,
        };

        if(this.input.timeInterval.end != null) data.end_time = this.input.date + " " + this.input.timeInterval.end;


        this.createEvent(data);
      } else if (this.tab == 1) {
        this.$refs.tableSelect_Search.$v.selectedTable.$touch();
        this.$refs.datePicker_Search.$v.selectedDate.$touch();

        const queryParams = {
          corntable_id: this.input.table,
          date: this.input.date,
        };

        this.searchEvent(queryParams);
        console.log("GET QUERY PARAMS:");
        console.log(queryParams);
      }
    },
    clearInputValues() {
      this.input.table = null;
      this.input.date = null;
      this.input.timeInterval.start = null;
      this.input.timeInterval.end = null;
      this.input.activity = null;
      this.apiError.show = false;
      this.apiError.message = "";
    },
  },
};
</script>

<style lang="scss">
.router-link {
  display: contents;
}
.content-wrapper {
  #finder-alert {
    position: absolute !important;
    top: 10%;
    z-index: 100;
    width: 376px;
  }
}
.finder-card {
  padding: 10px 20px;
  .card-header {
    padding: 40px 0px !important;
  }
  .card-body {
    .tabs {
      padding-bottom: 20px;
      .tab {
        justify-content: space-around;
        padding: 10px;
        font-size: 1.15rem;
        text-transform: unset !important;
      }
    }
    .form-container {
      .input-row {
        .input-col {
          padding-left: 0px;
          padding-right: 0px;
        }
        .table-name {
          padding-top: 20px;
          padding-bottom: 0px;
        }
        .date,
        .activity {
          padding-top: 5px;
          padding-bottom: 0px;
        }
        .activity {
          padding-bottom: 5px;
        }
      }
    }
  }
  .card-footer {
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-bottom: 40px !important;
  }
}

.datepicker-container {
  justify-content: center;
  padding: 16px;

  .cancel-btn {
    background-color: #fff !important;
    color: #000 !important;
  }
  .ok-btn,
  .cancel-btn {
    text-align: center;
    width: 45%;
  }
  .timepicker {
    display: flex;
    padding-top: 20px;

    .input-container {
      display: flex;
      .long-dash {
        display: flex;
        align-items: center;
        margin-left: 20px;
      }
      .start-time,
      .end-time {
        margin-left: 20px;
        width: 100px;
      }
    }
  }
  .card-footer {
    padding-top: 20px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    display: flex;
    justify-content: space-between;
  }
}
</style>